@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@400..900&family=Inter:wght@100..900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    scroll-behavior: smooth;
}

body {
    background: url("./assets/back-noise.png");
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #efefef;
    color: #4f4f4f;
    background-position: 0% 0%;
}

.guides.guides-two-columns {
    -ms-grid-columns: 51fr 18fr 51fr;
    grid-template-columns: 51fr 18fr 51fr;
}

::-webkit-scrollbar {
    width: 5px;
    background: white;
}

::-webkit-scrollbar-thumb {
    background: rgba(228, 84, 38, 0.4);
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.cinzel {
    font-family: 'Cinzel', serif;
}

.guides {
    position: relative;
    z-index: 1;
    display: -ms-grid;
    display: grid;
    width: 100%;
    justify-items: start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    grid-auto-columns: 1fr;
    -ms-grid-columns: 41fr 28fr 51fr;
    grid-template-columns: 41fr 28fr 51fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
}

.underline-calm {
    background: url("https://assets.website-files.com/62df9b11d1d3f86d3dacc2e3/62dfb0f0ba5843b89574cfe3_Wavy%20Line%20Flat.svg") repeat-x 0 40%;
    background-size: 20px;
}

.underline-large {
    background: url("https://assets.website-files.com/62df9b11d1d3f86d3dacc2e3/62df9b6fba58434cde73e0b5_Wavy%20Line.svg") repeat-x 0 40%;
    background-size: contain;
}

.background {
    background: url("./assets/back-noise.png");
    backgroud-color: #efefef;
}

.embla {
    max-width: 70rem;
    --slide-height: 19rem;
    --slide-spacing: 1rem;
    --slide-size: 100%;
    --slide-spacing-sm: 1.6rem;
    --slide-size-sm: 50%;
    --slide-spacing-lg: 2rem;
    --slide-size-lg: calc(100% / 3);
}

.embla__viewport {
    overflow: hidden;
}

.embla__container {
    backface-visibility: hidden;
    display: flex;
    touch-action: pan-y pinch-zoom;
    margin-left: calc(var(--slide-spacing) * -1);
}

@media (min-width: 750px) {
    .embla__container {
        margin-left: calc(var(--slide-spacing-sm) * -1);
    }
}

@media (min-width: 1200px) {
    .embla__container {
        margin-left: calc(var(--slide-spacing-lg) * -1);
    }
}

.embla__slide {
    min-width: 0;
    flex: 0 0 var(--slide-size);
    padding-left: var(--slide-spacing);
}

@media (min-width: 750px) {
    .embla__slide {
        flex: 0 0 var(--slide-size-sm);
        padding-left: var(--slide-spacing-sm);
    }
}

@media (min-width: 1200px) {
    .embla__slide {
        flex: 0 0 var(--slide-size-lg);
        padding-left: var(--slide-spacing-lg);
    }
}

.embla__slide__number {
    border-radius: 1.8rem;
    font-size: 4rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    height: var(--slide-height);
    user-select: none;
}

.embla__controls {
    display: grid;
    grid-template-columns: auto 1fr;
    justify-content: space-between;
    gap: 1.2rem;
    margin-top: 1.8rem;
}

.embla__buttons {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.6rem;
    align-items: center;
}

.embla__button {
    -webkit-appearance: none;
    appearance: none;
    background-color: transparent;
    touch-action: manipulation;
    display: inline-flex;
    text-decoration: none;
    cursor: pointer;
    border: 0;
    padding: 0;
    margin: 0;
    box-shadow: inset 0 0 0 0.2rem;
    width: 3.6rem;
    height: 3.6rem;
    z-index: 1;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.embla__button__svg {
    width: 35%;
    height: 35%;
}

.embla__dots {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    margin-right: calc((2.6rem - 1.4rem) / 2 * -1);
}

.embla__dot {
    -webkit-appearance: none;
    appearance: none;
    background-color: transparent;
    touch-action: manipulation;
    display: inline-flex;
    text-decoration: none;
    cursor: pointer;
    border: 0;
    padding: 0;
    margin: 0;
    width: 2.6rem;
    height: 2.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.embla__dot:after {
    box-shadow: inset 0 0 0 0.2rem;
    width: 1.4rem;
    height: 1.4rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    content: '';
}

.embla__dot--selected:after {
    box-shadow: inset 0 0 0 0.2rem;
}


.circles {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    margin-top: 6.25rem;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
}

.circle {
    position: relative;
    z-index: 1;
    margin-right: -3%;
    margin-left: -3%;
    padding-top: 29.5%;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    border-radius: 50%;
    box-shadow: inset 0 0 0 1px #4f4f4f;
    text-decoration: none;
}

.circle:hover {
    z-index: 2;
}

.circle.circle-first {
    margin-left: 0%;
}

.circle.circle-last {
    margin-right: 0%;
}

@media screen and (max-width: 991px) {
    .guides {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        -ms-grid-columns: 1fr 2fr;
        grid-template-columns: 1fr 2fr;
    }
}